export const toEventFormDataPayload = (
  formData: any,
  groupId: string,
  numberOfRounds: number
) => {
  const data = new FormData();

  Object.keys(formData).map((key) => {
    if (key === "roundInfo") {
      return data.append(key, JSON.stringify(formData[key]));
    } else {
      return data.append(key, formData[key]);
    }
  });

  data.append("groupId", groupId);
  data.append("rounds", numberOfRounds.toString());

  return data;
};
