import CreateEventModal from "components/event-management/CreateEventModal";
import EventsTable from "components/event-management/EventsTable";
import { useEventsByGroupId } from "modules/events/eventHooks";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SpinnerAnimation from "svg/SpinnerAnimation";

const ManageTour: React.FC = (props) => {
  const params: { id: string } = useParams();
  const [events, eventsQueryState] = useEventsByGroupId(params?.id);

  return (
    <Container>
      <Row className='mt-2'>
        <h1>Manage Events for Tour</h1>
      </Row>
      <Row className='mt-2'>
        <Col sm={2}>
          <CreateEventModal tourId={params?.id} />
        </Col>
      </Row>
      {eventsQueryState.isFinished ? (
        <EventsTable id={params?.id} events={events} />
      ) : (
        <div className='flex items-center justify-center h-screen w-full'>
          <SpinnerAnimation />
        </div>
      )}
    </Container>
  );
};

export default ManageTour;
