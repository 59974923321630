import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type UserState = {
  isAuthenticated?: boolean;
};

const initialState: UserState = {
  isAuthenticated: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAuthenticated(
      state,
      action: PayloadAction<{ isAuthenticated: boolean }>
    ): void {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

const { actions, reducer: userReducer } = slice;
const { setIsAuthenticated } = actions;
export { userReducer, setIsAuthenticated };
