import CreateGroupModal from "components/event-management/CreateGroupModal";
import GroupsTable from "components/event-management/GroupsTable";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";

const EventsDashboard: React.FC = () => {
  return (
    <Container>
      <Row className='mt-3'>
        <h1>Online Event Management</h1>
      </Row>
      <Row className='mt-4'>
        <Col sm={2}>
          <CreateGroupModal global />
        </Col>
        <Col sm={2}>
          <CreateGroupModal />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Tabs defaultActiveKey='public'>
          <Tab eventKey='public' title='Public Tours'>
            <GroupsTable isPublic={true} />
          </Tab>
          <Tab eventKey='private' title='Private Tours'>
            <GroupsTable isPublic={false} />
          </Tab>
        </Tabs>
      </Row>
    </Container>
  );
};

export default EventsDashboard;
