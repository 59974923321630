import { AddSimulatorPayload } from "QueryTypes";
import { QueryState } from "redux-query";
import { RunMutation, useMutation } from "redux-query-react";
import { EntitiesState } from "StoreTypes";
import {
  makeAddSimulatorMutation,
  makeDeleteSimulatorMutation,
} from "./simulatorQueries";

export const useDeleteSimulator = (
  facilityId: string,
  simulatorId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [deleteSimulatorQueryState, deleteSimulator] = useMutation(() =>
    makeDeleteSimulatorMutation(facilityId, simulatorId)
  );
  return [deleteSimulatorQueryState, deleteSimulator];
};

export const useAddSimulator = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [AddSimulatorPayload]>] => {
  const [addSimulatorQueryState, addSimulator] = useMutation(
    (body: AddSimulatorPayload) => makeAddSimulatorMutation(facilityId, body)
  );

  return [addSimulatorQueryState, addSimulator];
};
