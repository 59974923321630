import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { User } from "Models";
import {
  useAddUserToFacility,
  useFacilityUsers,
  useRemoveUsersFromFacility,
} from "modules/users/userHooks";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";

type OwnProps = {
  facilityId: string;
};

const ManageUsersModal: React.FC<OwnProps> = ({ facilityId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [, removeUsersFromFacility] = useRemoveUsersFromFacility(facilityId);
  const [, addUserToFacility] = useAddUserToFacility(facilityId);
  const [users, , refreshFacilityUsers] = useFacilityUsers(facilityId);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions: ReactNode = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to remove \r ${selectedRows.map(
            (r: User) => r.displayName
          )} \r from this facility?`
        )
      ) {
        const userIds = selectedRows.map((r: User) => r.id);
        removeUsersFromFacility({ userIds });
        setToggleCleared(!toggleCleared);
      }
    };

    return (
      <Button onClick={handleDelete} variant='danger'>
        Delete
      </Button>
    );
  }, [selectedRows, toggleCleared, removeUsersFromFacility]);

  const handleAddUser = async (values: { email: string }) => {
    await addUserToFacility(values);
    refreshFacilityUsers();
  };

  const columns: TableColumn<User>[] = useMemo(
    () => [
      {
        name: "User Email",
        selector: (row) => row.email,
      },
    ],
    []
  );

  return (
    <div>
      <Button onClick={() => setModalIsOpen(true)}>
        <FontAwesomeIcon icon={faUser} />
        Manage Users
      </Button>

      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Facility Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            title='Facility Users'
            columns={columns}
            data={users}
            dense
            highlightOnHover
            pagination
            responsive
            selectableRows
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
          />
          <Row>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values) => handleAddUser(values)}
            >
              {({ handleChange, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit} className='mt-4'>
                  <Form.Control
                    name='email'
                    onChange={handleChange}
                    placeholder='User Email'
                    value={values.email}
                  />
                  <div style={{ fontSize: "0.6rem" }}>
                    Only users who have already been created from the main
                    Clubhouse dashboard can be added here
                  </div>
                  <Button type='submit' variant='success' className='mt-2'>
                    Add User by Email
                  </Button>
                </Form>
              )}
            </Formik>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManageUsersModal;
