import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, Formik } from "formik";
import { Event } from "Models";
import { useAllCourses } from "modules/courses/courseHooks";
import {
  useEventMutation,
  useUpdateEventMutation,
} from "modules/events/eventHooks";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { HandicapPercentage } from "types/enums";
import { configEnumsAsSelectOptions } from "utils/enums/enumHelpers";
import { toEventFormDataPayload } from "utils/events/eventHelpers";
import { DateTime } from "luxon";

type OwnProps = {
  tourId: string;
  event?: Event;
};

const CreateEventModal: React.FC<OwnProps> = ({ tourId, event }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);
  const [, createEvent] = useEventMutation();
  const [, updateEvent] = useUpdateEventMutation(event?.id);
  const [courses] = useAllCourses();

  const datetimeLocalFromUtcIso = (date: string) => {
    return DateTime.fromISO(date)
      .setZone("utc")
      .toLocal()
      .toISO()
      .substring(0, 16);
  };

  const appendLocalTimezoneToIso = (date: string) => {
    return DateTime.fromISO(date).setZone("system").toISO();
  };

  return (
    <>
      {event ? (
        <Button onClick={handleOpen}>
          <FontAwesomeIcon icon={faEdit} />{" "}
        </Button>
      ) : (
        <Button onClick={handleOpen}>
          <FontAwesomeIcon icon={faPlus} />
          Create New Event
        </Button>
      )}

      <Modal show={modalIsOpen} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          {event ? "Edit Event" : "Create Event"}
        </Modal.Header>
        <Modal.Body>
          {
            <Formik
              onSubmit={async (values) => {
                const numberOfRounds = values.roundInfo.length;

                values.startDate = appendLocalTimezoneToIso(values.startDate);
                values.endDate = appendLocalTimezoneToIso(values.endDate);

                const data = toEventFormDataPayload(
                  values,
                  tourId,
                  numberOfRounds
                );
                if (event) {
                  await updateEvent(data);
                  handleClose();
                } else {
                  await createEvent(data);
                  handleClose();
                }
              }}
              initialValues={{
                name: event?.name || "",
                description: event?.description || "",
                type: event?.type || "tournament",
                startDate: event?.startDate
                  ? datetimeLocalFromUtcIso(event?.startDate)
                  : null || "",
                endDate: event?.endDate
                  ? datetimeLocalFromUtcIso(event?.endDate)
                  : null || "",
                replays: event?.replays || 1,
                mulligans: event?.mulligans.toString() || "false",
                applyHandicaps: event?.applyHandicaps?.toString() || "false",
                handicapMode: event?.handicapMode || 0,
                handicapLevel:
                  event?.handicapLevel || HandicapPercentage.ONE_HUNDRED,
                gimmes: event?.gimmes || 0,
                handicapCourseSlope:
                  event?.handicapCourseSlope?.toString() || "false",
                roundInfo: event?.roundInfo.length
                  ? event.roundInfo
                  : [
                      {
                        courseId: "",
                        holes: [],
                        pin: 1,
                        mensTees: 2,
                        womensTees: 0,
                        ground: 2,
                        sky: 0,
                        windspeed: 0,
                        windDirection: 270,
                      },
                    ],
              }}
            >
              {({ handleSubmit, handleChange, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Event Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Event Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Competition Type</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      aria-label="Competition Type"
                      name="type"
                      onChange={handleChange}
                      value={values.type}
                    >
                      <option value="tournament">Stroke</option>
                      <option value="long_drive">Long Drive</option>
                      <option value="closest_to_pin">Closest To Pin</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      <Col>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          name="startDate"
                          onChange={handleChange}
                          value={values.startDate}
                        />
                      </Col>
                      <Col>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          name="endDate"
                          onChange={handleChange}
                          value={values.endDate.toLocaleString()}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {!(values.type === "tournament") ? (
                    <Form.Group>
                      <Form.Label>Replays</Form.Label>
                      <Form.Control
                        type="number"
                        step="1"
                        min="1"
                        name="replays"
                        onChange={handleChange}
                        value={values.replays}
                      />
                    </Form.Group>
                  ) : null}
                  {values.type === "tournament" ? (
                    <>
                      <Form.Group>
                        <Form.Label>Apply Handicaps</Form.Label>
                        <Form.Check
                          type="radio"
                          label="On"
                          name="applyHandicaps"
                          onChange={handleChange}
                          value="true"
                          checked={values.applyHandicaps === "true"}
                        />
                        <Form.Check
                          type="radio"
                          label="Off"
                          name="applyHandicaps"
                          onChange={handleChange}
                          value="false"
                          checked={values.applyHandicaps === "false"}
                        />
                      </Form.Group>
                      {values.applyHandicaps === "true" && (
                        <Form.Group>
                          <Form.Label className="">Handicap Mode</Form.Label>
                          <Form.Control
                            as="select"
                            size="sm"
                            name="handicapMode"
                            onChange={handleChange}
                            value={values.handicapMode}
                          >
                            {configEnumsAsSelectOptions.eventsHandicapMode.map(
                              (option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </Form.Control>
                          <Form.Label className="">Handicap Level</Form.Label>
                          <Form.Control
                            as="select"
                            size="sm"
                            name="handicapLevel"
                            onChange={handleChange}
                            value={values.handicapLevel}
                          >
                            {configEnumsAsSelectOptions.eventsHandicapPercentage.map(
                              (option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </Form.Control>
                          <Form.Label className="">
                            Handicap Course Slope
                          </Form.Label>
                          <Form.Check
                            type="radio"
                            label="On"
                            name="handicapCourseSlope"
                            onChange={handleChange}
                            value="true"
                            checked={values.handicapCourseSlope === "true"}
                          />
                          <Form.Check
                            type="radio"
                            label="Off"
                            name="handicapCourseSlope"
                            onChange={handleChange}
                            value="false"
                            checked={values.handicapCourseSlope === "false"}
                          />
                        </Form.Group>
                      )}
                      <Form.Group>
                        <Form.Label>Gimme Distance</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          name="gimmes"
                          type="number"
                          onChange={handleChange}
                          value={values.gimmes}
                        >
                          {configEnumsAsSelectOptions.gimmeDistance.map(
                            (option) => (
                              <option value={option.value} key={option.value}>
                                {option.label}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Allow Mulligans</Form.Label>
                        <Form.Check
                          type="radio"
                          label="On"
                          name="mulligans"
                          onChange={handleChange}
                          value="true"
                          checked={values.mulligans === "true"}
                        />
                        <Form.Check
                          type="radio"
                          label="Off"
                          name="mulligans"
                          onChange={handleChange}
                          value="false"
                          checked={values.mulligans === "false"}
                        />
                      </Form.Group>
                    </>
                  ) : null}
                  <Form.Group className="mt-2">
                    <div className="mb-2">
                      {event?.imageUrl ? (
                        <>
                          <div>Existing Image:</div>
                          <img
                            alt="Event"
                            style={{ maxWidth: "200px" }}
                            src={event?.imageUrl}
                          />
                        </>
                      ) : null}
                    </div>
                    <Form.Label>Event Image</Form.Label>
                    <Form.Control
                      type="file"
                      name="file"
                      onChange={(event) =>
                        setFieldValue(
                          "file",
                          (event.currentTarget as HTMLInputElement).files?.[0]
                        )
                      }
                    />
                  </Form.Group>

                  {/* Round Info Field Array */}
                  <FieldArray
                    name="roundInfo"
                    render={(arrayHelpers) => (
                      <div className="my-4">
                        {values.roundInfo?.length < 3 ? (
                          <Button
                            className="mb-3"
                            variant="light"
                            onClick={() =>
                              arrayHelpers.push({
                                courseId: "",
                                holes: [],
                                pin: 1,
                                mensTees: 2,
                                womensTees: 3,
                                ground: 2,
                                sky: 0,
                                windspeed: 0,
                                windDirection: 270,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Round
                          </Button>
                        ) : null}
                        {values.roundInfo?.length > 0
                          ? values.roundInfo.map((round, index) => (
                              <div key={index} className="mb-4">
                                <Row>
                                  <h5>Round {index + 1}</h5>
                                </Row>
                                <Form.Group>
                                  <Form.Label>Course</Form.Label>
                                  <Form.Control
                                    as="select"
                                    size="sm"
                                    name={`roundInfo.${index}.courseId`}
                                    onChange={handleChange}
                                    value={values.roundInfo[index].courseId}
                                  >
                                    {courses.map((course) => (
                                      <option value={course.id}>
                                        {course.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Holes to Play</Form.Label>
                                  <Form.Control
                                    as="select"
                                    multiple
                                    size="sm"
                                    name={`roundInfo.${index}.holes`}
                                    onChange={handleChange}
                                    value={values.roundInfo[index].holes}
                                  >
                                    {Array(18)
                                      .fill(0)
                                      .map((e, i) => (
                                        <option key={i} value={i + 1}>
                                          {i + 1}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Pin Type</Form.Label>
                                  <Form.Control
                                    as="select"
                                    size="sm"
                                    name={`roundInfo.${index}.pin`}
                                    onChange={handleChange}
                                    value={values.roundInfo[index].pin}
                                  >
                                    <option value="0">Novice</option>
                                    <option value="1">Intermediate</option>
                                    <option value="2">Advanced</option>
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                  <Row>
                                    <Col>
                                      <Form.Label>Men's Tee</Form.Label>
                                      <Form.Control
                                        as="select"
                                        size="sm"
                                        type="number"
                                        name={`roundInfo.${index}.mensTees`}
                                        onChange={handleChange}
                                        value={values.roundInfo[index].mensTees}
                                      >
                                        {configEnumsAsSelectOptions.teeType.map(
                                          (teeType) => (
                                            <option
                                              key={teeType.value}
                                              value={teeType.value}
                                            >
                                              {teeType.label}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                    <Col>
                                      <Form.Label>Women's Tee</Form.Label>
                                      <Form.Control
                                        as="select"
                                        size="sm"
                                        type="number"
                                        name={`roundInfo.${index}.womensTees`}
                                        onChange={handleChange}
                                        value={
                                          values.roundInfo[index].womensTees
                                        }
                                      >
                                        {configEnumsAsSelectOptions.teeType.map(
                                          (teeType) => (
                                            <option
                                              key={teeType.value}
                                              value={teeType.value}
                                            >
                                              {teeType.label}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group>
                                  <Row>
                                    <Col>
                                      <Form.Label>Ground Conditions</Form.Label>
                                      <Form.Control
                                        as="select"
                                        size="sm"
                                        type="number"
                                        name={`roundInfo.${index}.ground`}
                                        onChange={handleChange}
                                        value={values.roundInfo[index].ground}
                                      >
                                        {configEnumsAsSelectOptions.ground.map(
                                          (ground) => (
                                            <option
                                              key={ground.value}
                                              value={ground.value}
                                            >
                                              {ground.label}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                    <Col>
                                      <Form.Label>Sky</Form.Label>
                                      <Form.Control
                                        as="select"
                                        size="sm"
                                        type="number"
                                        name={`roundInfo.${index}.sky`}
                                        onChange={handleChange}
                                        value={values.roundInfo[index].sky}
                                      >
                                        {configEnumsAsSelectOptions.weather.map(
                                          (weather) => (
                                            <option
                                              key={weather.value}
                                              value={weather.value}
                                            >
                                              {weather.label}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group>
                                  <Row>
                                    <Col>
                                      <Form.Label>Wind Speed</Form.Label>
                                      <Form.Control
                                        as="select"
                                        size="sm"
                                        type="number"
                                        name={`roundInfo.${index}.windspeed`}
                                        onChange={handleChange}
                                        value={
                                          values.roundInfo[index].windspeed
                                        }
                                      >
                                        {configEnumsAsSelectOptions.eventsWindSpeed.map(
                                          (eventsWindSpeed) => (
                                            <option
                                              key={eventsWindSpeed.value}
                                              value={eventsWindSpeed.value}
                                            >
                                              {eventsWindSpeed.label}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                    <Col>
                                      <Form.Label>Wind Direction</Form.Label>
                                      <Form.Control
                                        as="select"
                                        size="sm"
                                        type="number"
                                        name={`roundInfo.${index}.windDirection`}
                                        onChange={handleChange}
                                        value={
                                          values.roundInfo[index].windDirection
                                        }
                                      >
                                        {configEnumsAsSelectOptions.windDirection.map(
                                          (windDirection) => (
                                            <option
                                              key={windDirection.value}
                                              value={windDirection.value}
                                            >
                                              {windDirection.label}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </div>
                            ))
                          : null}
                      </div>
                    )}
                  />
                  <Form.Group className="mt-4">
                    <Button variant="success" type="submit">
                      {event ? "Update Event" : "Add Event"}
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateEventModal;
