import { normalize } from "normalizr";
import { AddSimulatorPayload, AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { simulator } from "./simulatorSchema";
const urls = {
  deleteSimulator(facilityId: string, simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulators/${simulatorId}`;
  },
  addSimulator(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/simulators/`;
  },
};
export const makeDeleteSimulatorMutation = (
  facilityId: string,
  simulatorId: string
): AppQueryConfig => ({
  url: urls.deleteSimulator(facilityId, simulatorId),
  queryKey: `deleteSimulator:${simulatorId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    simulators: (oldValue) => {
      delete oldValue[simulatorId];
      return oldValue;
    },
  },
});

export const makeAddSimulatorMutation = (
  facilityId: string,
  body: AddSimulatorPayload
): AppQueryConfig => ({
  url: urls.addSimulator(facilityId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson) => {
    return normalize(responseJson, simulator).entities;
  },
  update: {
    simulators: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
