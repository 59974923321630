import { userReducer } from "modules/users/userSlice";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { entitiesReducer, queriesReducer, queryMiddleware } from "redux-query";
import superagentInterface from "redux-query-interface-superagent";
import { RootState } from "StoreTypes";

const getQueries = (state: RootState) => state.queries;
const getEntities = (state: RootState) => state.entities;

const rootReducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  user: userReducer,
});

const middlewares = [
  queryMiddleware(superagentInterface, getQueries, getEntities),
];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
