import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useAllFacilities } from "modules/facilities/facilityHooks";
import { useCreateGroup } from "modules/groups/groupHooks";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type OwnProps = {
  global?: boolean;
};

const CreateGroupModal: React.FC<OwnProps> = ({ global }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);
  const [facilities] = useAllFacilities();
  const [createGroupQueryState, createGroup] = useCreateGroup(
    global ? true : false
  );
  console.log(global);
  return (
    <>
      <Button onClick={handleOpen}>
        <FontAwesomeIcon icon={faPlus} /> Create {global ? "Global" : "Private"}{" "}
        Tour
      </Button>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          Create a {global ? "Global" : "Private"} Tour
        </Modal.Header>
        <Modal.Body>
          {
            <Formik
              onSubmit={async (values) => {
                await createGroup(values);
                handleClose();
              }}
              initialValues={{ name: "", facilityId: facilities[0]?.id || "" }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Tour Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Form.Group>
                  {!global ? (
                    <Form.Group>
                      <Form.Label>Facility</Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        aria-label="Competition Type"
                        name="facilityId"
                        onChange={handleChange}
                        value={values.facilityId}
                      >
                        {facilities.map((facility) => (
                          <option value={facility.id}>{facility.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  ) : null}
                  <Form.Group className="mt-4">
                    <Button variant="success" type="submit">
                      Add {global ? "Global" : "Private"} Tour
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateGroupModal;
