import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacilityNormalized } from "EntityTypes";
import { useAllFacilities } from "modules/facilities/facilityHooks";
import { useMemo } from "react";
import { Button } from "react-bootstrap";
import DataTable, { Alignment, TableColumn } from "react-data-table-component";
import { useHistory } from "react-router";

const FacilitiesTable: React.FC = () => {
  const history = useHistory();
  const [facilities] = useAllFacilities();

  const sortedFacilities = facilities?.sort((a, b) =>
    a?.name > b?.name ? 1 : -1
  );

  const columns: TableColumn<FacilityNormalized>[] = useMemo(
    () => [
      {
        selector: (row) => row.name,
        name: "Facility Name",
      },
      {
        name: "Number of Sims",
        selector: (row) => row.simulators.length,
      },
      {
        name: "Number of Users",
        selector: (row) => row?.controlUserIds.length,
      },
      {
        cell: (row) => (
          <Button onClick={() => history.push(`facilities/${row.id}`)}>
            <FontAwesomeIcon icon={faCog} />
          </Button>
        ),
        ignoreRowClick: true,
        button: true,
        allowOverflow: true,
      },
    ],
    [history]
  );
  return (
    <div className='m-4'>
      <DataTable
        title='Facilities'
        columns={columns}
        data={sortedFacilities}
        dense
        highlightOnHover
        pagination
        responsive
        subHeaderWrap
        subHeaderAlign={Alignment.RIGHT}
      />
    </div>
  );
};

export default FacilitiesTable;
