import UsersTable from "components/user-management/UsersTable";
import { Container, Row } from "react-bootstrap";

const UserManagementDashboard: React.FC = () => {
  return (
    <Container>
      <Row>
        <UsersTable />
      </Row>
    </Container>
  );
};

export default UserManagementDashboard;
