import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ManageAdminModal: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <div>
      <Button onClick={() => setModalIsOpen(true)}>
        <FontAwesomeIcon icon={faPlus} /> Manage Admins
      </Button>

      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Admins</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Add User by Email</Form.Label>
              <Form.Control type='email' placeholder='Email' />
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManageAdminModal;
