import { Group } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { getGroupsQuery, makeCreateGroupMutation } from "./groupQueries";

export const useCreateGroup = (
  isPublic: boolean
): [
  QueryState,
  RunMutation<EntitiesState, [{ name: string; facilityId?: string }]>
] => {
  const [createGroupQueryState, createGroup] = useMutation(
    (body: { name: string; facilityId?: string }) =>
      makeCreateGroupMutation(isPublic, body)
  );

  return [createGroupQueryState, createGroup];
};

export const useGroups = (isPublic: boolean): UseDataInfo<Group[]> => {
  const [queryState, refreshGroups] = useRequest(getGroupsQuery(isPublic));
  const groups = useSelector((state: RootState): Group[] => {
    if (isPublic) {
      return state.entities?.publicGroups
        ? Object.values(state.entities.publicGroups)
        : [];
    } else {
      return state.entities?.privateGroups
        ? Object.values(state.entities.privateGroups)
        : [];
    }
  });

  return [groups, queryState, refreshGroups];
};
