import { FacilityNormalized } from "EntityTypes";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import {
  getAllFacilitiesQuery,
  makeAddFacilityMutation,
  makeDeleteFacilityMutation,
} from "./facilityQueries";

export const useAllFacilities = (): UseDataInfo<FacilityNormalized[]> => {
  const [queryState, refreshFacilities] = useRequest(getAllFacilitiesQuery());
  const facilities = useSelector((state: RootState): FacilityNormalized[] =>
    state.entities.facilities ? Object.values(state.entities.facilities) : []
  );
  return [facilities, queryState, refreshFacilities];
};

export const useAddFacility = (): [
  QueryState,
  RunMutation<EntitiesState, [{ name: string }]>
] => {
  const [addFacilityQueryState, addFacility] = useMutation(
    (body: { name: string }) => makeAddFacilityMutation(body)
  );

  return [addFacilityQueryState, addFacility];
};

export const useDeleteFacility = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [deleteFacilityQueryState, deleteFacility] = useMutation(() =>
    makeDeleteFacilityMutation(facilityId)
  );
  return [deleteFacilityQueryState, deleteFacility];
};
