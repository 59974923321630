import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Row } from "react-bootstrap";
import { Role, User } from "Models";
import { useState } from "react";
import { useAllRoles, useUpdateUserRoles } from "modules/roles/roleHooks";
import { Field, Form, Formik } from "formik";
import Select from "react-select";

type OwnProps = {
  user: User;
};

const UserManagementModal: React.FC<OwnProps> = ({ user }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);
  const [roles] = useAllRoles();

  const [updateRolesQueryState, updateRoles] = useUpdateUserRoles(user.id);

  return (
    <>
      <Button onClick={handleOpen}>
        <FontAwesomeIcon icon={faUserEdit} />
      </Button>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Manage {user.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h5>User Roles</h5>
          </Row>
          <Row>
            <Formik
              initialValues={{
                roles: user.roles,
              }}
              onSubmit={async (values) => {
                await updateRoles(values.roles);
                if (updateRolesQueryState.status === 200) {
                  setModalIsOpen(false);
                }
              }}
            >
              {({ values }) => (
                <Form>
                  <Row className='mb-4'>
                    <Field
                      name='roles'
                      options={roles.filter(
                        (role) =>
                          !user.roles.some(
                            (userRole) => role.id === userRole.id
                          )
                      )}
                      isMulti={true}
                      component={Select}
                      defaultValue={user.roles}
                      getOptionLabel={(role: Role) => role.description}
                      getOptionValue={(role: Role) => role}
                      onChange={(roles: Role[]) => {
                        values.roles = roles;
                      }}
                    />
                  </Row>
                  <Row className='mx-4'>
                    <Button variant='success' type='submit'>
                      <FontAwesomeIcon icon={faUserEdit} /> Update User
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserManagementModal;
