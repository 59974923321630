import { CourseNameInfo } from "Models";
import { normalize } from "normalizr";
import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { course } from "./courseSchema";

const urls = {
  courses(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/courses/name-info`;
  },
};

export const getAllCoursesQuery = (): AppQueryConfig => ({
  url: urls.courses(),
  options: {
    ...reduxQueryDefaultOptions(),
  },
  transform: (responseJson: CourseNameInfo[]) => {
    return normalize(responseJson, [course]).entities;
  },
  update: {
    courses: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
