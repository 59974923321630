import { faCog, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Simulator } from "Models";
import { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";

type OwnProps = {
  simulator: Simulator;
};
const SimulatorDetailsModal: React.FC<OwnProps> = ({ simulator }) => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <Button size='sm' onClick={() => setModalIsOpen(true)}>
        <FontAwesomeIcon icon={faCog} />
      </Button>

      <Modal show={isModalOpen} onHide={() => setModalIsOpen(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Sim Config</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h4>Device ID</h4>
          </Row>
          <Row>
            <Col>
              <input
                value={simulator.id}
                type='text'
                className='w-100'
                readOnly
              />
            </Col>
            <Col xs='auto'>
              <Button size='sm' onClick={() => handleCopy(simulator.id)}>
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Col>
          </Row>
          <Row>
            <h4>IoT Hub Access Key</h4>
          </Row>
          <Row>
            <Col>
              <input
                value={simulator.ioTAccessKey}
                type='text'
                className='w-100'
                readOnly
              />
            </Col>
            <Col xs='auto'>
              <Button
                size='sm'
                onClick={() => handleCopy(simulator.ioTAccessKey)}
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SimulatorDetailsModal;
