import { Event } from "Models";
import { AppQueryConfig } from "QueryTypes";
import { EntitiesByIntId } from "StoreTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";

const urls = {
  eventsByGroupId(groupId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events/groups/${groupId}/`;
  },
  events(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events`;
  },
  updateEventGroupId(eventId: number, groupId: number): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events/${eventId}/groups/${groupId}`;
  },
  eventsById(eventId: number): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/events/${eventId}`;
  },
};

export const getEventsByGroupIdQuery = (groupId: string): AppQueryConfig => ({
  url: urls.eventsByGroupId(groupId),
  queryKey: `getEventsByGroupId:${groupId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: Event[]) => {
    let obj: { [id: string]: Event } = {};
    responseJson.map((event) => (obj[event.id] = event));

    return {
      events: {
        byGroupId: {
          [groupId]: obj,
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => ({
      ...oldValue,
      byGroupId: {
        ...oldValue?.byGroupId,
        ...{
          [groupId]: {
            ...oldValue?.byGroupId?.[groupId],
            ...newValue.byGroupId[groupId],
          },
        },
      },
    }),
  },
});

export const makeEventMutation = (body: FormData): AppQueryConfig => ({
  url: urls.events(),
  options: {
    headers: {
      ...reduxQueryDefaultOptions().headers,
    },
    method: "POST",
  },
  body,
  transform: (responseJson: Event) => {
    let obj: EntitiesByIntId<Event> = {
      [responseJson.id]: responseJson,
    };

    const groupId = body.getAll("groupId")[0].toString();

    return {
      events: {
        byGroupId: {
          [groupId]: obj,
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => {
      const groupId = body.getAll("groupId")[0].toString();

      return {
        ...oldValue,
        byGroupId: {
          ...oldValue?.byGroupId,
          ...{
            [groupId]: {
              ...oldValue?.byGroupId?.[groupId],
              ...newValue.byGroupId[groupId],
            },
          },
        },
      };
    },
  },
});

export const updateEventMutation = (
  body: FormData,
  eventId?: number
): AppQueryConfig => ({
  url: eventId ? urls.eventsById(eventId) : "",
  options: {
    headers: {
      ...reduxQueryDefaultOptions().headers,
    },
    method: "PATCH",
  },
  body,
  transform: (responseJson: Event) => {
    let obj: EntitiesByIntId<Event> = {
      [responseJson.id]: responseJson,
    };

    const groupId = body.getAll("groupId")[0].toString();

    return {
      events: {
        byGroupId: {
          [groupId]: obj,
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => {
      const groupId = body.getAll("groupId")[0].toString();

      return {
        ...oldValue,
        byGroupId: {
          ...oldValue?.byGroupId,
          ...{
            [groupId]: {
              ...oldValue?.byGroupId?.[groupId],
              ...newValue.byGroupId[groupId],
            },
          },
        },
      };
    },
  },
});

export const makeUpdateEventGroupMutation = (
  eventId: number,
  groupId: number,
  previousGroupId: string
): AppQueryConfig => ({
  url: urls.updateEventGroupId(eventId, groupId),
  options: {
    headers: {
      "Content-Type": "multipart/form-data",
      ...reduxQueryDefaultOptions().headers,
    },
    method: "PATCH",
  },
  transform: (responseJson: Event) => {
    let obj: EntitiesByIntId<Event> = {
      [responseJson.id]: responseJson,
    };

    return {
      events: {
        byGroupId: {
          [groupId]: obj,
        },
      },
    };
  },
  update: {
    events: (oldValue, newValue) => {
      delete oldValue?.byGroupId?.[previousGroupId]?.[eventId];
      return {
        ...oldValue,
        byGroupId: {
          ...oldValue?.byGroupId,
          ...{
            [groupId]: {
              ...oldValue?.byGroupId?.[groupId],
              ...newValue.byGroupId[groupId],
            },
          },
        },
      };
    },
  },
});

export const makeDeleteEventMutation = (
  eventId: number,
  groupId: number
): AppQueryConfig => ({
  url: urls.eventsById(eventId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    events: (oldValue) => {
      delete oldValue?.byGroupId?.[groupId]?.[eventId];
      return oldValue;
    },
  },
});
