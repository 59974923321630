import { normalize } from "normalizr";
import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { privateGroup, publicGroup } from "./groupSchema";

const urls = {
  groups(isPublic: boolean): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${
      process.env.REACT_APP_BASE_URL
    }/api/groups/${isPublic ? "public" : "private"}`;
  },
};

export const makeCreateGroupMutation = (
  isPublic: boolean,
  body: { name: string; facilityId?: string }
): AppQueryConfig => ({
  url: urls.groups(isPublic),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson) => {
    return isPublic
      ? normalize(responseJson, publicGroup).entities
      : normalize(responseJson, privateGroup).entities;
  },
  update: {
    publicGroups: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
    privateGroups: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const getGroupsQuery = (isPublic: boolean): AppQueryConfig => ({
  url: urls.groups(isPublic),
  queryKey: `getGroups:${isPublic ? "Public" : "Private"}`,
  options: {
    ...reduxQueryDefaultOptions(),
  },
  transform: (responseJson) => {
    return isPublic
      ? normalize(responseJson, [publicGroup]).entities
      : normalize(responseJson, [privateGroup]).entities;
  },
  update: {
    publicGroups: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
    privateGroups: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
