import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group } from "Models";
import { useGroups } from "modules/groups/groupHooks";
import { Button } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import SpinnerAnimation from "svg/SpinnerAnimation";

type OwnProps = {
  isPublic: boolean;
};

const GroupsTable: React.FC<OwnProps> = ({ isPublic }) => {
  const history = useHistory();
  const [groups, groupsQueryState] = useGroups(isPublic);

  const columns: TableColumn<Group>[] = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    { name: "Updated At", selector: (row) => row.timestamp, sortable: true },
    {
      cell: (row) => (
        <Button onClick={() => history.push(`tours/${row.id}`)}>
          <FontAwesomeIcon icon={faCog} />
        </Button>
      ),
      ignoreRowClick: true,
      button: true,
      allowOverflow: true,
    },
  ];
  return (
    <>
      {groupsQueryState.isFinished ? (
        <DataTable
          title='Tours'
          columns={columns}
          data={groups}
          dense
          highlightOnHover
          pagination
          responsive
          defaultSortFieldId={3}
          defaultSortAsc={false}
        />
      ) : (
        <div className='flex items-center justify-center h-screen w-full mt-5'>
          <SpinnerAnimation />
        </div>
      )}
    </>
  );
};

export default GroupsTable;
