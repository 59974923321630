import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAllFacilities } from "modules/facilities/facilityHooks";
import { Formik } from "formik";
import axios from "axios";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { isAppError } from "utils/general/typechecks";
import { updateEntities } from "redux-query";
import { FacilityEntitiesState } from "StoreTypes";
import { useDispatch } from "react-redux";

const CreateUserModal: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleClose = async () => {
    await setModalIsOpen(false);
    setCreatedUserPassword("");
  };
  const handleOpen = () => setModalIsOpen(true);
  const [createdUserPassword, setCreatedUserPassword] = useState("");

  const [facilities] = useAllFacilities();
  const sortedFacilities = facilities.sort((a, b) =>
    a?.name > b?.name ? 1 : -1
  );

  const dispatch = useDispatch();

  return (
    <div>
      <Button onClick={handleOpen}>
        <FontAwesomeIcon icon={faPlus} /> Create User
      </Button>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Clubhouse User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <Formik
              onSubmit={async (values) => {
                try {
                  const res: {
                    data: { id: string; temporaryPassword: string };
                  } = await axios.post(
                    `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/users/`,
                    values,
                    { ...reduxQueryDefaultOptions() }
                  );

                  dispatch(
                    updateEntities({
                      facilities: (prev: FacilityEntitiesState) => ({
                        ...prev,
                        ...{
                          [values.facilityId]: {
                            ...{
                              ...prev[values.facilityId],
                              ...{
                                controlUserIds: prev[
                                  values.facilityId
                                ]?.controlUserIds.concat(res.data.id),
                              },
                            },
                          },
                        },
                      }),
                    })
                  );

                  setCreatedUserPassword(
                    `User successfully created with password: ${res.data.temporaryPassword}`
                  );
                } catch (error) {
                  if (isAppError(error)) {
                    setCreatedUserPassword(error.response.data);
                  }
                }
              }}
              initialValues={{
                user: { displayName: "", email: "" },
                password: "",
                facilityId: facilities?.[0]?.id,
              }}
            >
              {createdUserPassword ? (
                <>
                  <Button onClick={() => setCreatedUserPassword("")}>
                    Back
                  </Button>
                  <h5>{createdUserPassword}</h5>
                </>
              ) : (
                ({ handleSubmit, handleChange, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Name'
                        name='user.displayName'
                        onChange={handleChange}
                        value={values.user?.displayName}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Email'
                        name='user.email'
                        onChange={handleChange}
                        value={values.user?.email}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='Password'
                        name='password'
                        onChange={handleChange}
                        value={values.password}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Facility</Form.Label>
                      <Form.Control
                        as='select'
                        aria-label='Facility Selection'
                        name='facilityId'
                        onChange={handleChange}
                        value={values.facilityId}
                      >
                        {sortedFacilities.map((facility) => (
                          <option key={facility.id} value={facility.id}>
                            {facility.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-4'>
                      <Button variant='success' type='submit'>
                        Add User
                      </Button>
                    </Form.Group>
                  </Form>
                )
              )}
            </Formik>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateUserModal;
