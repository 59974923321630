import { Facility } from "Models";
import { normalize } from "normalizr";
import { AppQueryConfig } from "QueryTypes";
import { FacilityEntitiesState, SimulatorEntitiesState } from "StoreTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { facilityAndSimSchema } from "./facilitySchema";

const urls = {
  allFacilities(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities`;
  },
  singleFacility(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}`;
  },
};

/**
 * Query for a user's facilities.
 * @param userId
 */
export const getAllFacilitiesQuery = (): AppQueryConfig => ({
  url: urls.allFacilities(),
  queryKey: `getAllFacilities`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: Facility[]) => {
    return normalize(responseJson, [facilityAndSimSchema]).entities;
  },
  update: {
    facilities: (oldValue, newValue): FacilityEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
    simulators: (oldValue, newValue): SimulatorEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeAddFacilityMutation = (body: {
  name: string;
}): AppQueryConfig => ({
  url: urls.allFacilities(),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: Facility) => {
    return normalize(responseJson, facilityAndSimSchema).entities;
  },
  update: {
    facilities: (oldValue, newValue): FacilityEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
    simulators: (oldValue, newValue): SimulatorEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeDeleteFacilityMutation = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.singleFacility(facilityId),
  queryKey: `deleteFacility:${facilityId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  optimisticUpdate: {
    facilities: (oldValue) => {
      delete oldValue[facilityId];
      return oldValue;
    },
  },
});
