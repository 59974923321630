import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteEvent } from "modules/events/eventHooks";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

type OwnProps = {
  eventId: number;
  groupId: number;
};
export const DeleteEventConfirmationModal: React.FC<OwnProps> = ({
  eventId,
  groupId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, deleteEvent] = useDeleteEvent();
  const handleDelete = async () => {
    const res = await deleteEvent(eventId, groupId);
    if (!(res?.status === 200)) {
      toast.error(`Error: ${res?.text}`, { position: "bottom-right" });
    }
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete event?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this event?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
