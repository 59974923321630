import { Role } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { getAllRolesQuery, makeUpdateUserRoles } from "./roleQueries";

export const useAllRoles = (): UseDataInfo<Role[]> => {
  const [queryState, refreshRoles] = useRequest(getAllRolesQuery());
  const roles = useSelector((state: RootState): Role[] =>
    state.entities.roles ? Object.values(state.entities.roles) : []
  );

  return [roles, queryState, refreshRoles];
};

export const useUpdateUserRoles = (
  userId: string
): [QueryState, RunMutation<EntitiesState, [Role[]]>] => {
  const [updateRolesQueryState, updateRoles] = useMutation((body: Role[]) =>
    makeUpdateUserRoles(userId, body)
  );

  return [updateRolesQueryState, updateRoles];
};
