import { Formik } from "formik";
import { userLogin, setIsAuthenticated } from "modules/users";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const submitForm = async (values: { username: string; password: string }) => {
    const loggedIn = await userLogin(values);
    dispatch(setIsAuthenticated({ isAuthenticated: loggedIn }));
  };
  return (
    <Container>
      <Row>
        <h1>Login to aG Admin</h1>
      </Row>
      <Row>
        <Col sm='6'>
          <Formik
            onSubmit={(values) => submitForm(values)}
            initialValues={{ username: "", password: "" }}
          >
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className='mb-3'>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type='email'
                    name='username'
                    onChange={handleChange}
                    value={values.username}
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    name='password'
                    onChange={handleChange}
                    value={values.password}
                  />
                </Form.Group>
                <Button variant='success' type='submit'>
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
