import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDeleteSimulator } from "modules/simulators/simulatorHooks";

type OwnProps = {
  facilityId: string;
  simulatorId: string;
};

const DeleteSimulatorConfirmationModal: React.FC<OwnProps> = ({
  facilityId,
  simulatorId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteSimulatorQueryState, deleteSimulator] = useDeleteSimulator(
    facilityId,
    simulatorId
  );
  const handleDelete = () => {
    deleteSimulator();
  };

  useEffect(() => {
    deleteSimulatorQueryState.isFinished ?? setIsModalOpen(false);
  }, [deleteSimulatorQueryState]);

  return (
    <div>
      <Button size='sm' onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete simulator?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this simulator?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteSimulatorConfirmationModal;
