import { clearUserToken } from "modules/users";
import { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import logo from "../logo.svg";

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const useHandleLogout = async () => {
    await clearUserToken();
    window.location.href = "/";
  };

  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  return (
    <Navbar bg='light' expand='md'>
      <Navbar.Brand href='/'>
        <img src={logo} alt='aG Logo' style={{ height: "50px" }} />
        aG Admin Controls
      </Navbar.Brand>
      <Navbar.Toggle onClick={() => setIsOpen(!isOpen)} />
      <Navbar.Collapse className='justify-content-between'>
        <Nav className='mr-auto justify-content-start' navbar>
          <Nav.Link href='/clubhouse'>Clubhouse</Nav.Link>
          <Nav.Link href='/users'>User Management</Nav.Link>
          <Nav.Link href='/events'>Event Management</Nav.Link>
            <Nav.Link href='/marketing'>Marketing Hub</Nav.Link>
        </Nav>
        <Nav className='justify-content-end'>
          {isAuthenticated && (
            <Nav.Link onClick={useHandleLogout}>Logout</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
