import { Col, Container, Row } from "react-bootstrap";
import AddFacilityModal from "../components/clubhouse/AddFacilityModal";
import CreateUserModal from "../components/clubhouse/CreateUserModal";
import FacilitiesTable from "../components/clubhouse/FacilitiesTable";
import ManageAdminModal from "../components/clubhouse/ManageAdminModal";

const ClubhouseDashboard: React.FC = () => {
  return (
    <Container>
      <Row className='mt-3'>
        <h1>Clubhouse Admin Controls</h1>
      </Row>
      <Row className='mt-4'>
        <Col sm={2}>
          <AddFacilityModal />
        </Col>
        <Col sm={2}>
          <CreateUserModal />
        </Col>
        <Col sm={2}>
          <ManageAdminModal />
        </Col>
      </Row>
      <Row>
        <FacilitiesTable />
      </Row>
    </Container>
  );
};

export default ClubhouseDashboard;
