import {
  BlobDeleteIfExistsResponse,
  BlobDeleteOptions,
  BlobServiceClient,
} from "@azure/storage-blob";
import toast from "react-hot-toast";

const account = "agmarketinghub";
const sas = process.env.REACT_APP_AZURE_SAS;
const containerName = "marketing";
const blobServiceClient = new BlobServiceClient(
  `https://${account}.blob.core.windows.net?${sas}`
);
const containerClient = blobServiceClient.getContainerClient(containerName);

export const getBlobsInContainer = async () => {
  const returnedBlobUrls = [];

  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    const blobItem = {
      url: `https://${account}.blob.core.windows.net/${containerName}/${blob.name}?${sas}`,
      name: blob.name,
    };

    returnedBlobUrls.push(blobItem);
  }

  return returnedBlobUrls;
};

export const createBlobInContainer = async (file: File) => {
  const blobClient = containerClient.getBlockBlobClient(file.name);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  await blobClient.uploadData(file, options);
  toast.success(`uploaded ${file.name}`, { position: "bottom-right" });
};

export async function deleteBlobIfItExists(blobName: string): Promise<void> {
  const blockBlobClient = await containerClient.getBlockBlobClient(blobName);

  const options: BlobDeleteOptions = {
    deleteSnapshots: "include",
  };
  const blobDeleteIfExistsResponse: BlobDeleteIfExistsResponse =
    await blockBlobClient.deleteIfExists(options);

  if (!blobDeleteIfExistsResponse.errorCode) {
    toast.success(`Deleted ${blobName}`, { position: "bottom-right" });
  }
}

export const uploadFileToBlob = async (file: File | null): Promise<void> => {
  if (!file) return;
  await createBlobInContainer(file);
};

export function humanReadableFileName(fileName: string): string {
  const fileNameWithoutExtension = fileName.split(".")[0];
  const words = fileNameWithoutExtension.split("_");
  const humanReadableWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return humanReadableWords.join(" ");
}
