import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteFacilityConfirmationModal from "components/clubhouse/DeleteFacilityConfirmationModal";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { RootState } from "StoreTypes";
import AddSimulatorModal from "../components/clubhouse/AddSimulatorModal";
import ManageUsersModal from "../components/clubhouse/ManageUsersModal";
import SimulatorsTable from "../components/clubhouse/SimulatorsTable";

const ManageFacility: React.FC = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const facility = useSelector(
    (state: RootState) => state.entities.facilities?.[params.id]
  );

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <Button
            variant='link'
            onClick={() => history.push("/clubhouse")}
            className='mt-2'
          >
            <FontAwesomeIcon icon={faBackward} /> Go Back to Clubhouse
            Management
          </Button>
        </Col>
      </Row>
      <Row className='mt-3'>
        <h1>{facility?.name}</h1>
      </Row>
      <Row className='mt-4'>
        <Col sm={2}>
          <AddSimulatorModal facilityId={params.id} />
        </Col>
        <Col sm={2}>
          <ManageUsersModal facilityId={params.id} />
        </Col>
        <Col sm={2}>
          <DeleteFacilityConfirmationModal facilityId={params.id} />
        </Col>
      </Row>
      <Row>
        <SimulatorsTable facilityId={params.id} />
      </Row>
    </Container>
  );
};

export default ManageFacility;
