import { User } from "Models";
import { useAllUsers } from "modules/users/userHooks";
import { useMemo } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import UserManagementModal from "./UserManagementModal";

const UsersTable: React.FC = () => {
  const [users] = useAllUsers();
  const columns: TableColumn<User>[] = useMemo(
    () => [
      { selector: (row) => row.displayName, name: "Name" },
      { selector: (row) => row.email, name: "Email" },
      {
        cell: (row) => <UserManagementModal user={row} />,
        ignoreRowClick: true,
        button: false,
        allowOverflow: true,
      },
    ],
    []
  );
  return (
    <div className='m-4'>
      <DataTable
        title='Users'
        columns={columns}
        data={users}
        dense
        highlightOnHover
        pagination
        responsive
        subHeaderWrap
      />
    </div>
  );
};

export default UsersTable;
