import { Event } from "Models";
import DataTable, { TableColumn } from "react-data-table-component";
import CreateEventModal from "./CreateEventModal";
import { DeleteEventConfirmationModal } from "./DeleteEventConfirmationModal";
import MoveEventModal from "./MoveEventModal";

type OwnProps = {
  id: string;
  events: Event[];
};

const EventsTable: React.FC<OwnProps> = ({ id, events }) => {
  const columns: TableColumn<Event>[] = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "No. of Rounds",
      selector: (row) => row.rounds,
    },
    {
      name: "Edit",
      cell: (event) => <CreateEventModal tourId={id} event={event} />,
    },
    {
      name: "Move",
      cell: (event) => <MoveEventModal tourId={id} eventId={event.id} />,
    },
    {
      name: "Delete",
      cell: (event) => (
        <DeleteEventConfirmationModal
          eventId={event.id}
          groupId={event.groupId}
        />
      ),
    },
  ];

  return (
    <>
      <DataTable
        title='Events'
        columns={columns}
        data={events}
        dense
        highlightOnHover
        pagination
        responsive
      />
    </>
  );
};

export default EventsTable;
