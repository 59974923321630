import "./App.css";
import { Container, Row } from "react-bootstrap";
import Navigation from "./components/Navigation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ClubhouseDashboard from "./views/ClubhouseDashboard";
import ManageFacility from "./views/ManageFacility";
import Login from "views/Login";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { useAuthenticateToken } from "modules/users";
import UserManagementDashboard from "views/UserManagementDashboard";
import EventsDashboard from "views/EventsDashboard";
import ManageTour from "views/ManageTour";
import { Toaster } from "react-hot-toast";
import MarketingHub from "./views/MarketingHub";

const App: React.FC = () => {
  useAuthenticateToken(true);

  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  return (
    <Router>
      <Container fluid={true}>
        <Row>
          <Toaster />
          <Navigation />
          <Row>
            {isAuthenticated ? (
              <Switch>
                <Route path="/clubhouse" component={ClubhouseDashboard}></Route>
                <Route
                  path="/users"
                  component={UserManagementDashboard}
                ></Route>
                <Route path="/facilities/:id" component={ManageFacility} />
                <Route path="/events" component={EventsDashboard} />
                <Route path="/tours/:id" component={ManageTour} />
                <Route exact path="/marketing" component={MarketingHub} />
                <Route exact path="/" component={ClubhouseDashboard} />
              </Switch>
            ) : (
              <Login />
            )}
          </Row>
        </Row>
      </Container>
    </Router>
  );
};

export default App;
