import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useAddFacility } from "modules/facilities/facilityHooks";
import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddFacilityModal: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleClose = () => setModalIsOpen(false);
  const handleOpen = () => setModalIsOpen(true);
  const [, addFacility] = useAddFacility();

  const handleAdd = async (values: { name: string }) => {
    await addFacility(values);
    handleClose();
  };
  return (
    <div>
      <Button onClick={handleOpen}>
        <FontAwesomeIcon icon={faPlus} /> Add Facility
      </Button>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => handleAdd(values)}
            initialValues={{ name: "" }}
          >
            {({ handleChange, handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Label>Facility Name</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  placeholder='Facility Name'
                  value={values.name}
                  onChange={handleChange}
                />
                <Button variant='success' type='submit' className='mt-4'>
                  Add Facility
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddFacilityModal;
