import { Simulator } from "Models";
import { RootState } from "StoreTypes";

export const getAllSimulatorsByFacilityId = (
  state: RootState,
  facilityId: string
): Simulator[] => {
  return state.entities.simulators
    ? Object.values(state.entities.simulators).filter(
        (simulator) => simulator.facilityId === facilityId
      )
    : [];
};
