import { Simulator } from "Models";
import { getAllSimulatorsByFacilityId } from "modules/simulators";
import { useMemo } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import SimulatorDetailsModal from "./SimulatorDetailsModal";
import { useAllFacilities } from "modules/facilities/facilityHooks";
import DeleteSimulatorConfirmationModal from "./DeleteSimulatorConfirmationModal";

type OwnProps = {
  facilityId: string;
};

const SimulatorsTable: React.FC<OwnProps> = ({ facilityId }) => {
  useAllFacilities();
  const facilitySimulators = useSelector((state: RootState) =>
    getAllSimulatorsByFacilityId(state, facilityId)
  );
  const columns: TableColumn<Simulator>[] = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
      },
      {
        name: "Name",
        selector: (row) => row.name,
      },
      {
        name: "Caddy Version",
        selector: (row) => row.caddyVersion,
      },
      {
        name: "Online Install Id",
        selector: (row) => row.installId,
      },
      {
        cell: (sim) => (
          <DeleteSimulatorConfirmationModal
            facilityId={facilityId}
            simulatorId={sim.id}
          />
        ),
        ignoreRowClick: true,
        button: true,
        allowOverflow: true,
      },
      {
        cell: (sim) => <SimulatorDetailsModal simulator={sim} />,
        ignoreRowClick: true,
        button: true,
        allowOverflow: true,
      },
    ],
    [facilityId]
  );

  return (
    <DataTable
      title='Simulators'
      columns={columns}
      data={facilitySimulators}
      dense
      highlightOnHover
      pagination
      responsive
    />
  );
};

export default SimulatorsTable;
