import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { useAddSimulator } from "modules/simulators/simulatorHooks";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type OwnProps = {
  facilityId: string;
};

const AddSimulatorModal: React.FC<OwnProps> = ({ facilityId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, addSimulator] = useAddSimulator(facilityId);
  const handleAdd = async (values: { name: string }) => {
    await addSimulator(values);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faPlus} /> Add Sim
      </Button>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Simulator to Facility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ name: "" }}
            onSubmit={(values) => handleAdd(values)}
          >
            {({ handleChange, values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Simulator Name</Form.Label>
                  <Form.Control
                    placeholder='Sim Name'
                    name='name'
                    onChange={handleChange}
                    value={values.name}
                  />
                </Form.Group>
                <Button type='submit' variant='success' className='mt-4'>
                  Add Simlator
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddSimulatorModal;
