import { Facility, User } from "Models";
import { facilityAndSimSchema } from "modules/facilities/facilitySchema";
import { normalize } from "normalizr";
import { AppQueryConfig } from "QueryTypes";
import { EntitiesById } from "StoreTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { user } from "./userSchema";

const urls = {
  allUsers(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/users`;
  },
  facilityUsers(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facilities/${facilityId}/users`;
  },
  makeAdmin(userId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/users/${userId}/make-admin`;
  },
};

export const getAllUsersQuery = (): AppQueryConfig => ({
  url: urls.allUsers(),
  queryKey: `allUsers`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: User[]) => {
    return normalize(responseJson, [user]).entities;
  },
  update: {
    users: (oldValue, newValue): EntitiesById<User> => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const getFacilityUsersQuery = (facilityId: string): AppQueryConfig => ({
  url: urls.facilityUsers(facilityId),
  queryKey: `facilityUsers:${facilityId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: User[]) => {
    return normalize(responseJson, [user]).entities;
  },
  update: {
    users: (oldValue, newValue): EntitiesById<User> => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeAddUserToFacilityMutation = (
  facilityId: string,
  body: { email: string }
): AppQueryConfig => ({
  url: urls.facilityUsers(facilityId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: Facility[]) => {
    return normalize(responseJson, facilityAndSimSchema).entities;
  },
  update: {
    facilities: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
    simulators: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeRemoveUsersFromFacilityQuery = (
  facilityId: string,
  body: { userIds: string[] }
): AppQueryConfig => ({
  url: urls.facilityUsers(facilityId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "DELETE",
  },
  body,
  transform: (responseJson: Facility[]) => {
    return normalize(responseJson, facilityAndSimSchema).entities;
  },
  update: {
    facilities: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
    simulators: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeUserAdminQuery = (userId: string): AppQueryConfig => ({
  url: urls.makeAdmin(userId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  transform: (responseJson: User) => {
    return normalize(responseJson, user).entities;
  },
  update: {
    users: (oldValue, newValue): EntitiesById<User> => ({
      ...oldValue,
      ...newValue,
    }),
  },
});
