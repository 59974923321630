import { User } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useDispatch, useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import { setIsAuthenticated } from ".";
import { makeUserAdminQuery } from "./userQueries";
import {
  getAllUsersQuery,
  getFacilityUsersQuery,
  makeAddUserToFacilityMutation,
  makeRemoveUsersFromFacilityQuery,
} from "./userQueries";

export const useAuthenticateToken = (enabled: boolean) => {
  const dispatch = useDispatch();
  if (enabled) {
    if (localStorage.getItem("JWT")) {
      dispatch(setIsAuthenticated({ isAuthenticated: true }));
      return true;
    } else {
      setIsAuthenticated({ isAuthenticated: false });
      return false;
    }
  }
};

export const useAllUsers = (): UseDataInfo<User[]> => {
  const [queryState, refreshAllUsers] = useRequest(getAllUsersQuery());
  const users = useSelector((state: RootState) =>
    state.entities.users ? Object.values(state.entities.users) : []
  ).sort((a, b) => (a?.displayName > b?.displayName ? 1 : -1));

  return [users, queryState, refreshAllUsers];
};

export const useFacilityUsers = (facilityId: string): UseDataInfo<User[]> => {
  const [queryState, refreshFacilityUsers] = useRequest(
    getFacilityUsersQuery(facilityId)
  );
  const facilityUsers = useSelector(
    (state: RootState) =>
      state.entities.facilities?.[facilityId]?.controlUserIds
  );
  const reduxUsers = useSelector((state: RootState) =>
    state.entities.users ? Object.values(state.entities.users) : []
  );
  const users = reduxUsers?.filter((user) => facilityUsers?.includes(user.id));

  return [users, queryState, refreshFacilityUsers];
};

export const useAddUserToFacility = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [{ email: string }]>] => {
  const [addUserToFacilityQueryState, addUserToFacility] = useMutation(
    (body: { email: string }) => makeAddUserToFacilityMutation(facilityId, body)
  );
  return [addUserToFacilityQueryState, addUserToFacility];
};

export const useRemoveUsersFromFacility = (
  facilityId: string
): [QueryState, RunMutation<EntitiesState, [{ userIds: string[] }]>] => {
  const [addUserToFacilityQueryState, removeUsersFromFacility] = useMutation(
    (body: { userIds: string[] }) =>
      makeRemoveUsersFromFacilityQuery(facilityId, body)
  );
  return [addUserToFacilityQueryState, removeUsersFromFacility];
};

export const useMakeUserAdmin = (
  userId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [makeUserAdminQueryState, makeUserAdmin] = useMutation(() =>
    makeUserAdminQuery(userId)
  );
  return [makeUserAdminQueryState, makeUserAdmin];
};
