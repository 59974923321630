import { CourseNameInfo } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { RootState } from "StoreTypes";
import { getAllCoursesQuery } from "./courseQueries";

export const useAllCourses = (): UseDataInfo<CourseNameInfo[]> => {
  const [queryState, refreshCourses] = useRequest(getAllCoursesQuery());
  const courses = useSelector((state: RootState) =>
    state.entities?.courses ? Object.values(state.entities.courses) : []
  );

  return [courses, queryState, refreshCourses];
};
