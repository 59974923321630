import { faTruckMoving } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { Group } from "Models";
import { useUpdateEventGroup } from "modules/events/eventHooks";
import { useGroups } from "modules/groups/groupHooks";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type OwnProps = {
  tourId: string;
  eventId: number;
};

const MoveEventModal: React.FC<OwnProps> = ({ tourId, eventId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);
  const [privateGroups] = useGroups(false);
  const [publicGroups] = useGroups(true);
  const [updateEventGroupQueryState, updateEventGroup] = useUpdateEventGroup();

  return (
    <>
      <Button onClick={handleOpen}>
        <FontAwesomeIcon icon={faTruckMoving} />
      </Button>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>Move Event</Modal.Header>
        <Modal.Body>
          {
            <Formik
              onSubmit={async (values) => {
                await updateEventGroup(eventId, values.groupId, tourId);
                if (updateEventGroupQueryState.isFinished) {
                  handleClose();
                }
              }}
              initialValues={{ groupId: 0 }}
            >
              {({ handleSubmit, handleChange, values }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Group to Move Event To</Form.Label>
                    <Form.Control
                      as='select'
                      size='sm'
                      name='groupId'
                      onChange={handleChange}
                      value={values.groupId}
                    >
                      <optgroup label='Public Tours'>
                        {publicGroups.map((group: Group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label='Private Tours'>
                        {privateGroups.map((group: Group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className='mt-4'>
                    <Button variant='success' type='submit'>
                      Move Event
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MoveEventModal;
