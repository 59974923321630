import { BlobList } from "Models";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  deleteBlobIfItExists,
  getBlobsInContainer,
  humanReadableFileName,
  uploadFileToBlob,
} from "utils/azure/azureHelpers";

const MarketingHub: React.FC = () => {
  const storageConfigured = true;
  const [fileList, setFileList] = useState<BlobList[]>([]);
  const [fileSelected, setFileSelected] = useState<File | null>();
  const [fileUploaded, setFileUploaded] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  useEffect(() => {
    getBlobsInContainer().then((list: any) => {
      setFileList(list);
    });
  }, [fileUploaded]);

  const onFileChange = (event: any) => {
    setFileSelected(event.target.files[0]);
  };

  const onFileUpload = async () => {
    if (fileSelected && fileSelected?.name) {
      setUploading(true);

      await uploadFileToBlob(fileSelected);

      setFileSelected(null);
      setFileUploaded(fileSelected.name);
      setUploading(false);
      setInputKey(Math.random().toString(36));
    }
  };

  const DisplayForm = () => (
    <div className="row">
      <div className="col-4">
        <input
          type="file"
          onChange={onFileChange}
          key={inputKey || ""}
          className="form-control col-3"
        />
      </div>
      <div className="col-2">
        <button
          type="submit"
          className="btn btn-primary btn-md"
          onClick={onFileUpload}
        >
          Upload!
        </button>
      </div>
    </div>
  );
  return (
    <Container>
      <Row className="mt-2">
        <h2 className="text-md text-gray-400">aG Marketing Hub</h2>
      </Row>
      <Row className="mt-2">
        <Col>
          <div>
            <strong>Upload file to Azure Blob Storage</strong>
            <br />
            <br />
            {storageConfigured && !uploading && DisplayForm()}
            {storageConfigured && uploading && <div>Uploading</div>}
          </div>
        </Col>
      </Row>
      <div className="row mt-4">
        <strong className="mt-4">Files available for download</strong>
        {fileList?.map((file, index) => (
          <div className="row mt-1" key={index}>
            <div className="col-4">{humanReadableFileName(file.name)}</div>
            <div className="col-1">
              <a
                href={file.url}
                target="_blank"
                rel="noreferrer"
                className="btn btn-link btn-sm"
              >
                View
              </a>
            </div>
            <div className="col-1">
              <button
                type="button"
                onClick={() => {
                  deleteBlobIfItExists(file.name);
                }}
                className="btn btn-danger btn-sm"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default MarketingHub;
