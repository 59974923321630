import { Role } from "Models";
import { normalize } from "normalizr";
import { AppQueryConfig } from "QueryTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
import { role } from "./roleSchema";

const urls = {
  allRoles(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/roles`;
  },
  userRoles(userId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/users/${userId}`;
  },
};

export const getAllRolesQuery = (): AppQueryConfig => ({
  url: urls.allRoles(),
  queryKey: "getAllRoles",
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: Role[]) => {
    return normalize(responseJson, [role]).entities;
  },
  update: {
    roles: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeUpdateUserRoles = (
  userId: string,
  body: Role[]
): AppQueryConfig => ({
  url: urls.userRoles(userId),
  queryKey: `updateUserRoles:${userId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "PATCH",
  },
  body,
  optimisticUpdate: {
    users: (oldValue) => ({
      ...oldValue,
      ...{
        [userId]: {
          ...oldValue[userId],
          ...{ roles: body },
        },
      },
    }),
  },
});
