import { schema } from "normalizr";

const facility = new schema.Entity("facilities");

const simulator = new schema.Entity("simulators", {
  facilities: [facility],
});

export const facilityAndSimSchema = new schema.Entity("facilities", {
  simulators: [simulator],
});
