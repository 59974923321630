import { Event } from "Models";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { QueryState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import { EntitiesState, RootState } from "StoreTypes";
import {
  getEventsByGroupIdQuery,
  makeDeleteEventMutation,
  makeEventMutation,
  makeUpdateEventGroupMutation,
  updateEventMutation,
} from "./eventQueries";

export const useEventsByGroupId = (groupId: string): UseDataInfo<Event[]> => {
  const [queryState, refreshEvents] = useRequest(
    getEventsByGroupIdQuery(groupId)
  );

  const events = useSelector((state: RootState): Event[] =>
    state.entities.events?.byGroupId?.[groupId]
      ? Object.values(state.entities.events?.byGroupId?.[groupId])
      : []
  );

  return [events, queryState, refreshEvents];
};

export const useEventMutation = (): [
  QueryState,
  RunMutation<EntitiesState, [FormData]>
] => {
  const [createEventQueryState, createEvent] = useMutation((body: FormData) =>
    makeEventMutation(body)
  );

  return [createEventQueryState, createEvent];
};

export const useUpdateEventMutation = (
  eventId?: number
): [QueryState, RunMutation<EntitiesState, [FormData]>] => {
  const [updateEventQueryState, updateEvent] = useMutation((body: FormData) =>
    updateEventMutation(body, eventId)
  );
  return [updateEventQueryState, updateEvent];
};

export const useUpdateEventGroup = (): [
  QueryState,
  RunMutation<
    EntitiesState,
    [eventId: number, groupId: number, previousGroupId: string]
  >
] => {
  const [updateEventGroupQueryState, updateEventGroup] = useMutation(
    (eventId: number, groupId: number, previousGroupId: string) =>
      makeUpdateEventGroupMutation(eventId, groupId, previousGroupId)
  );

  return [updateEventGroupQueryState, updateEventGroup];
};

export const useDeleteEvent = (): [
  QueryState,
  RunMutation<EntitiesState, [eventId: number, groupId: number]>
] => {
  const [deleteEventQueryState, deleteEvent] = useMutation(
    (eventId: number, groupId: number) =>
      makeDeleteEventMutation(eventId, groupId)
  );

  return [deleteEventQueryState, deleteEvent];
};
