import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteFacility } from "modules/facilities/facilityHooks";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";

type OwnProps = {
  facilityId: string;
};

const DeleteFacilityConfirmationModal: React.FC<OwnProps> = ({
  facilityId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, deleteFacility] = useDeleteFacility(facilityId);
  const history = useHistory();
  const handleDelete = async () => {
    await deleteFacility();
    await setIsModalOpen(false);
    history.push("/clubhouse");
  };
  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>
        <FontAwesomeIcon icon={faTrashAlt} /> Delete Facility
      </Button>

      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete facility?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this facility?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteFacilityConfirmationModal;
